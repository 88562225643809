@keyframes flicker {
	0% {
		opacity: 1;
	}
	3% {
		opacity: 0.4;
	}
	6% {
		opacity: 1;
	}
	7% {
		opacity: 0.4;
	}
	8% {
		opacity: 1;
	}
	9% {
		opacity: 0.4;
	}
	10% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

#Yes {
	animation: flicker 6s infinite step-end;
}

#slash {
	animation: flicker 5s infinite 2s step-end;
}
